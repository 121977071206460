<template>
    <div class="row w-100">
        <div class="w-100" v-if="isLoadGames">
            <p  v-if="games.length == 0" class="text-center my-2">
                {{$t('validation.no_myrooms')}}
            </p>
        <table class=" tab table b-table text-center" role="table" v-else>
            <thead>
            <tr>
                <th scope="col" @click="sort('id')" role="columnheader"  :aria-sort="(currentSort == 'id')?currentSortDir:none">#</th>
                <th scope="col" @click="sort('users_count')" role="columnheader"  :aria-sort="(currentSort == 'users_count')?currentSortDir:none">{{$t('koth.games.users')}}</th>
                <th scope="col" @click="sort('type')" role="columnheader"  :aria-sort="(currentSort == 'type')?currentSortDir:none">{{$t('koth.games.type')}}</th>
                <th scope="col" @click="sort('king_time')" role="columnheader"  :aria-sort="(currentSort == 'king_time')?currentSortDir:none">{{$t('koth.games.king_time')}}</th>
                <th scope="col" @click="sort('score')" role="columnheader"  :aria-sort="(currentSort == 'score')?currentSortDir:none">{{$t('koth.games.score')}}</th>
                <th scope="col" @click="sort('winner.username')" role="columnheader"  :aria-sort="(currentSort == 'winner.username')?currentSortDir:none">{{$t('koth.games.winner')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="room in games_sorted" v-bind:key="room.id">
                <td data-label="#">
                    <router-link :to="{name:'KothRoom',params:{id:room.id}}">#{{room.id}}</router-link>
                    <br/>
                    <span :title="$t('koth.games.join_at')">{{new Date(room.created_at).toLocaleString()}}</span>
                </td>
                <td :data-label="$t('koth.games.users')">{{room.users_count}}</td>
                <td :data-label="$t('koth.games.type')">{{room.type}}</td>
                <td :data-label="$t('koth.games.king_time')">{{room.users[0].king_time}}</td>
                <td :data-label="$t('koth.games.score')">{{room.score}}</td>
                <td :data-label="$t('koth.games.winner')">
                    <span v-if="room.winner">{{room.winner.username}}</span>
                    <span v-else> {{'-'}} </span>
                </td>

            </tr>
            </tbody>
        </table>

        </div>
        <div v-else class="w-100 mx-3">
            <b-skeleton-table :rows="4" :columns="7"></b-skeleton-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "myGames",
        data(){
            return{
                games:[],
                none:'none',
                currentSort:'begin_at',
                currentSortDir:'descending',
                isLoadGames:false,
            }
        },
        created(){
            this.getGames()

        },
      computed:{
        games_sorted:function() {
          let games = this.games;
          return games.sort((a,b) => {
            let modifier = 1;
            if(this.currentSortDir === 'descending') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
          });
        }
      },
        methods:{
            getGames() {
                this.$koth
                    .get('me/rooms')
                    .then(response => {

                        this.games = response.data.map( p => ({ ...p,
                          score:p.score?parseInt(p.score):0,
                          winner:  (p.solves.length && !p.pass_time) ? p.solves[0] : null
                        }));
                        this.isLoadGames = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
        }
    }
</script>

<style scoped>

</style>