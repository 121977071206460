<template>


   <main>
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 py-4">
                <h1 class="mb-4">{{$t('koth.title')}}</h1>
                <p class="font-size-16">{{$t('koth.desc')}}</p>
            </div>
          <div class="col-xl-5">

              <img
                  src="@/assets/images/crisis/koth.png"
                  alt style="width: 500px;height: 400px"
                  class="img-fluid ml-auto d-block"
              />

          </div>
        </div>
        <div class="row m-0  justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-6 w-50 col-sm-12 mb-2">
                <b-button class="show-btn" v-b-modal.create-room>
                    <h4 class="mb-2">{{$t('koth.create.t')}}</h4>
                    <p class="text-muted">{{$t('koth.create.p')}}</p>
                </b-button>
                <create-room/>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 w-50 col-sm-12">
                <b-button class="show-btn" v-b-modal.join-room>
                    <h4 class="mb-2">{{$t('koth.join.t')}}</h4>
                    <p class="text-muted">{{$t('koth.join.p')}}</p>
                </b-button>
            <join-room/>
            </div>
        </div>
        <div class="row m-0 py-5">
            <div class="col-xl-11 m-auto">
                <h3 class="mb-3 text-center">{{$t('koth.current_rooms.t')}}</h3>
                <p class="mb-3 text-center">{{$t('koth.current_rooms.p')}}</p>


            <last-rooms />
            </div>
        </div>
        <div class="row m-0 py-2">
            <div class="col-xl-12 mb-3">
                <h3 class="text-center">{{$t('koth.games.t')}}</h3>
            </div>

            <div class="col-xl-11 m-auto">
                <my-games/>
            </div>

        </div>

        <div class="row col-xl-11 m-auto px-5 mb-3">
          <div class="col-xl-9 ">
            <div role="tablist" class="mb-3">
              <b-card no-body class="mb-1 ">
                <b-card-header header-tag="header" role="tab" v-b-toggle.rules style="cursor: pointer">
                  <h5 class="m-0 text-dark">
                    {{$t('koth.rules.t')}}
                  </h5>
                </b-card-header>
                <b-collapse id="rules" visible class="mx-1" accordion="my-accordion" role="tabpanel">

                  <ol class="m-2">
                    <li>{{$t('koth.rules.l1')}}</li>
                    <li>{{$t('koth.rules.l2')}}</li>
                    <li>{{$t('koth.rules.l3')}}</li>
                    <li>{{$t('koth.rules.l4')}}</li>
                    <li>{{$t('koth.rules.l5')}}</li>
                    <li>{{$t('koth.rules.l6')}}</li>
                    <li>{{$t('koth.rules.l7')}}</li>
                    <li>{{$t('koth.rules.l8')}}</li>
                    <li>{{$t('koth.rules.l9')}}</li>
                  </ol>

                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab" v-b-toggle.how style="cursor: pointer">
                  <h5 class="m-0 text-dark">
                    {{$t('koth.rules.t2')}}
                  </h5>
                </b-card-header>
                <b-collapse id="how" accordion="my-accordion" role="tabpanel">

                  <ul class="m-2">
                    <li>{{$t('koth.rules.r1')}}</li>
                    <li>{{$t('koth.rules.r2')}}</li>
                    <li>{{$t('koth.rules.r3')}}</li>
                    <li>{{$t('koth.rules.r4')}}</li>
                    <li>{{$t('koth.rules.r5')}}</li>
                    <li>{{$t('koth.rules.r6')}}</li>
                  </ul>

                </b-collapse>
              </b-card>

            </div>
          </div>
            <div class="col-xl-3 ml-auto ">
                <h3 class="my-3">{{$t('koth.machines.t')}}</h3>
               <machines/>
            </div>
        </div>
   </main>
</template>
<script>
    import CreateRoom from "../../components/koth/createRoom";
    import JoinRoom from "../../components/koth/joinRoom";
    import Machines from "../../components/koth/machines";
    import LastRooms from "../../components/koth/lastRooms";
    import MyGames from "../../components/koth/myGames";
    export default {
        name: "Koth",
        components: {
            MyGames,
            LastRooms,
            Machines,
            JoinRoom,
            CreateRoom

        },



    }
</script>

<style scoped>

    img {
        width: 200px;
        height: 200px;
    }


    #accordion {
        position: center;
    }

    #games {
        margin: auto;
    }

    img {
        width: 300px;
        height: 250px;
        animation: 5s bounce linear infinite;
    }
    li{
        margin-bottom: 10px;
    }

    .show-btn {
        width: 100%;
        height: 130px;
        border-radius: 5px;
       border:1px solid #343994;
        background-color: #7e79fc;
        -webkit-transition: all 0.5s ease-in-out;
    }

    .show-btn:hover {
        -webkit-transform: scale(1.03);
        box-shadow: 0 0 10px #79829D;
    }
    .card-header {
      background-color: #7e79fc;
      border-radius: 6px;
      border-bottom: 0 solid #343994;
    }
    .card{
    background-color: rgba(126, 121, 252,0.1);
      border-radius: 6px;
      color: rgba(256,256,256,0.8);
    }
    .collapse{
      padding: 12px 0px;
    }
    li{
      margin-bottom: 14px;
    }
</style>
