<template>
    <div class="row w-100">
        <div class="w-100 border p-3" v-if="isLoadMachines ">
        <div  class="w-100"  v-if="machines.length">
            <h6  v-for="(machine,index) in machines"  v-bind:key="machine.id">
                <b-avatar :src="machine.icon_os" class="mr-3"></b-avatar>
                <span>{{machine.name}}</span>
                <hr v-if="index<machines.length-1"/>
            </h6>


        </div>
            <p  v-else class="text-center my-2">
                {{$t('validation.no_machines')}}
            </p>
        </div>
        <div v-else class="w-100 mx-3">
            <b-skeleton-table :rows="4" :columns="1"></b-skeleton-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "machines",
        data() {
            return {
                machines: [],
                isLoadMachines:false,
            };

        },
        created(){
            this.getMachines()
        },
        methods:{
            getMachines() {
                this.$koth
                    .get('machines')
                    .then(response => {
                        this.machines = response.data;
                        this.isLoadMachines = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    })

            },
        }
    }
</script>

<style scoped>

</style>