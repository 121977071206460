<template>
    <b-modal id="create-room" ref="modal" centered hide-footer :title="$t('koth.create.t')" @shown="create">

        <b-card-text>
          <form id="form"  method="POST" @submit.prevent="handleSubmit">
            <div class="form-group row mx-0  mt-3">
              <div class="col-xl-5 p-0">
                <label>{{$t('koth.games.duration') }}:</label>

                <b-form-select type="text" v-model="room.duration" class="form-control"
                               :class="{ 'is-invalid': typesubmit && $v.room.duration.$error }">
                       <b-form-select-option v-for="d in [5,10,20,30,60,120]" :key="d" :value="d">{{d +' '+$t('koth.games.min')}}</b-form-select-option>
                </b-form-select>
                <div v-if="typesubmit && $v.room.duration.$error" class="invalid-feedback">
                  <span v-if="!$v.room.duration.required"> {{$t('validation.required')}}</span>
                </div>
              </div>
              <div class="col-xl-6 ml-auto p-0">
                <div class="form-group ">
                  <label>{{$t('koth.games.type') }}:</label>
                  <div class="row m-0 justify-content-center">
                    <b-form-checkbox v-model="room.type" class="mr-4" value="public">Public</b-form-checkbox>
                    <b-form-checkbox v-model="room.type" value="private">Private</b-form-checkbox>
                  </div>

                </div>
              </div>

            </div>


            <div class="form-group mt-3 mb-0">
              <input type="submit" class="btn float-right btn-primary"  :value="$t('koth.games.create')"/>
            </div>
          </form>
        </b-card-text>

    </b-modal>
</template>

<script>
  import {required} from "vuelidate/lib/validators";
  import Swal from "sweetalert2";
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: false,
    animation: false,
    timer: 2000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  export default {
    name:"createRoom",

  data(){
    return {
      typesubmit: false,
      room:{}
    }
  },
    validations: {
      room:{
        duration: {required},
      },
    },
  methods: {
      create(){
        this.typesubmit = false
                this.room={}
      },

    hideModal() {
      this.room={}
      this.$refs['modal'].hide()
    },
    handleSubmit() {
      this.typesubmit = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {

        return;

      }
      this.createRoom();

    },
    createRoom(){
      this.$koth
              .post('rooms',this.room)
              .then(response => {
                console.log(response.data)
                Toast.fire({icon: 'success', title: 'Success'})
                this.$router.push({name:'KothRoom',params:{id:response.data.id}})
              })
              .catch(error => {
                console.log(error)
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
              })
    }

  },

}
</script>

<style scoped>



</style>
