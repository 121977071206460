<template>
    <div  class="row w-100">
        <div  class="w-100" v-if="isLoadCurrent">
            <p  v-if="rooms.length == 0" class="text-center my-2">
                {{$t('validation.no_rooms')}}
            </p>
            <table class=" tab table b-table text-center" role="table" v-else>
                <thead>
                <tr>
                    <th scope="col" @click="sort('id')" role="columnheader"  :aria-sort="(currentSort == 'id')?currentSortDir:none">#</th>
                    <th scope="col" @click="sort('begin_at')" role="columnheader"  :aria-sort="(currentSort == 'begin_at')?currentSortDir:none">{{$t('koth.current_rooms.begin_at')}}</th>
                    <th scope="col" @click="sort('finish_at')" role="columnheader"  :aria-sort="(currentSort == 'finish_at')?currentSortDir:none">{{$t('koth.current_rooms.finish_at')}}</th>
                    <th scope="col" @click="sort('king_changes')" role="columnheader"  :aria-sort="(currentSort == 'king_changes')?currentSortDir:none">{{$t('koth.current_rooms.king_changes')}}</th>
                    <th scope="col" @click="sort('machine')" role="columnheader"  :aria-sort="(currentSort == 'machine')?currentSortDir:none">{{$t('koth.current_rooms.machine')}}</th>
                    <th scope="col" @click="sort('users_count')" role="columnheader"  :aria-sort="(currentSort == 'users_count')?currentSortDir:none">{{$t('koth.current_rooms.users')}}</th>
                    <th scope="col" @click="sort('winner')" role="columnheader"  :aria-sort="(currentSort == 'winner')?currentSortDir:none">{{$t('koth.current_rooms.winner')}}</th>
                    <th scope="col"></th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="room in rooms_sorted" v-bind:key="room.id">
                    <td data-label="#">
                        <router-link :to="{name:'KothRoom',params:{id:room.id}}">#{{room.id}}</router-link>
                    </td>
                    <td :data-label="$t('koth.current_rooms.begin_at')">{{room.begin_at}}</td>
                    <td :data-label="$t('koth.current_rooms.finish_at')">{{room.finish_at}}</td>
                    <td :data-label="$t('koth.current_rooms.king_changes')">{{room.king_changes}}</td>
                    <td :data-label="$t('koth.current_rooms.machine')">
                      <span v-if="room.machine">{{room.machine.name}}</span>
                      <span v-else> - </span>
                    </td>
                    <td :data-label="$t('koth.current_rooms.users')">{{room.users_count?room.users_count:1}}</td>
                    <td :data-label="$t('koth.current_rooms.current_king')">
                        <span v-if="room.winner">{{room.winner.username}}</span>
                        <span v-else> - </span>
                    </td>
                    <td class="text-center" style="min-width: 100px">
                        <button v-if="room.is_joined && !room.is_finished" class="btn btn-primary" disabled>Joined</button>
                        <button v-if="!room.is_joined && !room.is_finished" class="btn btn-success" @click="join(room.id)" >Join</button>
                        <button v-if="room.is_finished" class="btn btn-secondary" disabled >Finished</button>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
        <div v-else class="w-100 mx-3">
            <b-skeleton-table :rows="4" :columns="7"></b-skeleton-table>
        </div>
    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import Vue from "vue";

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        animation: false,
        timer: 2000,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    export default {
        name: "lastRooms",

        data() {
            return {
                rooms: [],
                none:'none',
              currentSort:'begin_at',
              currentSortDir:'descending',
              isLoadCurrent:false,
            };

        },
      computed:{
        rooms_sorted:function() {
          let rooms = this.rooms;
          return rooms.sort((a,b) => {
            let modifier = 1;
            if(this.currentSortDir === 'descending') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
          });
        }
      },
      mounted()
      {
        var koth_channel = this.$store.state.pusher_koth.subscribe('private-koth');
        var socketId = koth_channel.pusher.connection.socket_id;
        Vue.prototype.$http.defaults.headers.common['X-Socket-Id'] = socketId;
        koth_channel.bind('rooms_added',  (data) =>  {
          console.log("rooms_added",data);
          data.is_joined = 0;
          this.rooms.unshift(data)

        });
        koth_channel.bind('rooms_deleted',  (data) =>  {
          console.log("rooms_deleted",data);
          let index = this.rooms.findIndex(item => item.id === data.room_id);
          if (index != -1) this.rooms.splice(index, 1);
        });
      },
        created() {

            this.getRooms()

        },
        methods: {
            join(id){
                this.$koth
                    .post('rooms/'+id+'/join')
                    .then(response => {
                        console.log(response)
                        this.$router.push({ name: 'KothRoom', params: { id: id }})
                        Toast.fire({icon: 'success', title: 'Success'})
                    })
                    .catch(error => {
                        console.log(error)
                        Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                    })
            },



            getRooms() {
                this.$koth
                    .get('rooms')
                    .then(response => {
                        this.rooms = response.data.data.map(p => ({
                        ...p,
                        winner: (p.solves.length && !p.pass_time) ? p.solves[0] : null
                      }));
                        this.isLoadCurrent = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },

            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
        },
    }
</script>

<style scoped>

</style>